import React from "react";
import Layout from "@components/layout";
import Header from "@components/header/header";
import MobileNavInner from "@components/mobile-nav-inner";
import PageHeader from "@components/page-header";
import BlogPage from "@components/blog/blog-page";
import Subscribe from "@components/subscribe";
import Footer from "@components/footer";

const PrivacyPolicySection = () => {
  return (
    <>
        <Layout pageTitle="Privacy and Policy Page || WICEBOT">
        <Header/>
      <div class="container p-20 mb-lg-5 mb-md-5 mb-sm-2">
        <h1>Privacy Policy</h1>

        <p>Last updated January 23, 2022</p>
        <br />
        <p>
          Thank you for choosing to be part of our community at wicebot
          ("Company," "we," "us," or "our"). We are committed to protecting your
          personal information and your right to privacy. If you have any
          questions or concerns about this privacy notice or our practices with
          regard to your personal information, please contact us at{" "}
          <b>
            <i>
              <a href="mailto:team@wicebot.guru">
                team@wicebot.guru
              </a>
            </i>
          </b>
          .
        </p>
        <br />
        <p>
          This privacy notice describes how we might use your information if
          you:
        </p>
        <ul>
          <li>
            Visit our website at
            <a href=" http://www.wicebot.guru">http://www.wicebot.guru</a>
          </li>
          <li>
            Engage with us in other related ways ― including any sales,
            marketing, or events
          </li>
        </ul>
        <p>In this privacy notice, if we refer to:</p>
        <ul>
          <li>
            "Website," we are referring to any website of ours that references
            or links to this policy
          </li>
          <li>
            "Services," we are referring to our Website, and other related
            services, including any sales, marketing, or events
          </li>
        </ul>
        <p>
          The purpose of this privacy notice is to explain to you in the
          clearest way possible what information we collect, how we use it, and
          what rights you have in relation to it. If there are any terms in this
          privacy notice that you do not agree with, please discontinue use of
          our Services immediately
        </p>
        <p>
          Please read this privacy notice carefully, as it will help you
          understand what we do with the information that we collect.
        </p>
        <div class="index">
          <h2>TABLE OF CONTENTS</h2>
          <ol>
            <li>
              <a href="#1"> WHAT INFORMATION DO WE COLLECT?</a>
            </li>
            <li>
              <a href="#2">HOW DO WE USE YOUR INFORMATION?</a>
            </li>
            <li>
              <a href="#3">WILL YOUR INFORMATION BE SHARED WITH ANYONE? </a>
            </li>
            <li>
              <a href="#4">
                DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
              </a>
            </li>
            <li>
              <a href="#5">HOW LONG DO WE KEEP YOUR INFORMATION?</a>
            </li>
            <li>
              <a href="#6">HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
            </li>
            <li>
              <a href="#7">DO WE COLLECT INFORMATION FROM MINORS? </a>
            </li>
            <li>
              <a href="#8">WHAT ARE YOUR PRIVACY RIGHTS?</a>
            </li>
            <li>
              <a href="#9">CONTROLS FOR DO-NOT-TRACK FEATURES</a>
            </li>
            <li>
              <a href="#10">
                DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
              </a>
            </li>
            <li>
              <a href="#11">DO WE MAKE UPDATES TO THIS NOTICE? </a>
            </li>
            <li>
              <a href="#12">HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </a>
            </li>
            <li>
              <a href="#13">
                HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM
                YOU?
              </a>
            </li>
            <li>
              <a href="#14"> CREDIT</a>
            </li>
          </ol>
        </div>
        <div id="1">
          <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>
          <p>Personal information you disclose to us</p>
          <p>
            <i>
              In Short: We collect personal information that you provide to us.
            </i>
          </p>
          <p>
            We collect personal information that you voluntarily provide to us
            when you register on the Website, express an interest in obtaining
            information about us or our products and Services, when you
            participate in activities on the Website or otherwise when you
            contact us.
          </p>
          <p>
            The personal information that we collect depends on the context of
            your interactions with us and the Website, the choices you make and
            the products and features you use. The personal information we
            collect may include the following:
          </p>
          <p>
            Personal Information Provided by You. We collect names; email
            addresses; usernames; contact or authentication data; billing
            addresses; debit/credit card numbers; phone numbers; passwords; and
            other similar information.
          </p>
          <p>
            All personal information that you provide to us must be true,
            complete and accurate, and you must notify us of any changes to such
            personal information.
          </p>
        </div>
        <div id="2">
          <h2>2. HOW DO WE USE YOUR INFORMATION?</h2>
          <p>
            <i>
              In Short: We process your information for purposes based on
              legitimate business interests, the fulfillment of our contract
              with you, compliance with our legal obligations, and/or your
              consent.
            </i>
          </p>
          <p>
            We use personal information collected via our Website for a variety
            of business purposes described below. We process your personal
            information for these purposes in reliance on our legitimate
            business interests, in order to enter into or perform a contract
            with you, with your consent, and/or for compliance with our legal
            obligations. We indicate the specific processing grounds we rely on
            next to each purpose listed below.
          </p>
          <p>We use the information we collect or receive:</p>
          <ul>
            <li>
              To facilitate account creation and logon process. If you choose to
              link your account with us to a third-party account (such as your
              Google or Facebook account), we use the information you allowed us
              to collect from those third parties to facilitate account creation
              and logon process for the performance of the contract.
            </li>
            <li>
              To post testimonials. We post testimonials on our Website that may
              contain personal information. Prior to posting a testimonial, we
              will obtain your consent to use your name and the content of the
              testimonial. If you wish to update, or delete your testimonial,
              please contact us at{" "}
              <b>
                <i>
                  <a href="mailto:team@wicebot.guru">
                    team@wicebot.guru
                  </a>
                </i>
              </b>{" "}
              and be sure to include your name, testimonial location, and
              contact information.
            </li>
            <li>
              Request feedback. We may use your information to request feedback
              and to contact you about your use of our Website.
            </li>
            <li>
              To enable user-to-user communications. We may use your information
              in order to enable user-to-user communications with each user's
              consent.
            </li>
            <li>
              To manage user accounts. We may use your information for the
              purposes of managing our account and keeping it in working order.
            </li>
            <li>
              To send administrative information to you. We may use your
              personal information to send you product, service and new feature
              information and/or information about changes to our terms,
              conditions, and policies.
            </li>
            <li>
              To protect our Services. We may use your information as part of
              our efforts to keep our Website safe and secure (for example, for
              fraud monitoring and prevention).
            </li>
            <li>
              To enforce our terms, conditions and policies for business
              purposes, to comply with legal and regulatory requirements or in
              connection with our contract.
            </li>
            <li>
              To respond to legal requests and prevent harm. If we receive a
              subpoena or other legal request, we may need to inspect the data
              we hold to determine how to respond.
            </li>
            <li>
              Fulfill and manage your orders. We may use your information to
              fulfill and manage your orders, payments, returns, and exchanges
              made through the Website.
            </li>
            <li>
              Administer prize draws and competitions. We may use your
              information to administer prize draws and competitions when you
              elect to participate in our competitions.
            </li>
            <li>
              To deliver and facilitate delivery of services to the user. We may
              use your information to provide you with the requested service.
            </li>
            <li>
              To respond to user inquiries/offer support to users. We may use
              your information to respond to your inquiries and solve any
              potential issues you might have with the use of our Services.
            </li>
            <li>
              To send you marketing and promotional communications. We and/or
              our third-party marketing partners may use the personal
              information you send to us for our marketing purposes, if this is
              in accordance with your marketing preferences. For example, when
              expressing an interest in obtaining information about us or our
              Website, subscribing to marketing or otherwise contacting us, we
              will collect personal information from you. You can opt-out of our
              marketing emails at any time (see the "WHAT ARE YOUR PRIVACY
              RIGHTS?" below).
            </li>
            <li>
              Deliver targeted advertising to you. We may use your information
              to develop and display personalized content and advertising (and
              work with third parties who do so) tailored to your interests
              and/or location and to measure its effectiveness.
            </li>
          </ul>
        </div>
        <div id="3">
          <h2>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h2>
          <p>
            <i>
              In Short: We only share information with your consent, to comply
              with laws, to provide you with services, to protect your rights,
              or to fulfill business obligations.
            </i>
          </p>
          <p>
            We may process or share your data that we hold based on the
            following legal basis:
          </p>
          <ul>
            <li>
              Consent: We may process your data if you have given us specific
              consent to use your personal information for a specific purpose.
            </li>
            <li>
              Legitimate Interests: We may process your data when it is
              reasonably necessary to achieve our legitimate business interests.
            </li>
            <li>
              Performance of a Contract: Where we have entered into a contract
              with you, we may process your personal information to fulfill the
              terms of our contract.
            </li>
            <li>
              Legal Obligations: We may disclose your information where we are
              legally required to do so in order to comply with applicable law,
              governmental requests, a judicial proceeding, court order, or
              legal process, such as in response to a court order or a subpoena
              (including in response to public authorities to meet national
              security or law enforcement requirements).
            </li>
            <li>
              Vital Interests: We may disclose your information where we believe
              it is necessary to investigate, prevent, or take action regarding
              potential violations of our policies, suspected fraud, situations
              involving potential threats to the safety of any person and
              illegal activities, or as evidence in litigation in which we are
              involved.
            </li>
          </ul>
          <p>
            More specifically, we may need to process your data or share your
            personal information in the following situations:
          </p>
          <ul>
            <li>
              Business Transfers. We may share or transfer your information in
              connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of
              our business to another company.
            </li>
            <li>
              Affiliates. We may share your information with our affiliates, in
              which case we will require those affiliates to honor this privacy
              notice. Affiliates include our parent company and any
              subsidiaries, joint venture partners or other companies that we
              control or that are under common control with us.
            </li>
            <li>
              Business Partners. We may share your information with our business
              partners to offer you certain products, services or promotions.
            </li>
          </ul>
        </div>
        <div id="4">
          <h2>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
          <p>
            <i>
              In Short: We may use cookies and other tracking technologies to
              collect and store your information.
            </i>
          </p>
          <p>
            We may use cookies and similar tracking technologies (like web
            beacons and pixels) to access or store information. Specific
            information about how we use such technologies and how you can
            refuse certain cookies is set out in our Cookie Notice.
          </p>
        </div>
        <div id="5">
          <h2>5. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
          <p>
            <i>
              In Short: We keep your information for as long as necessary to
              fulfill the purposes outlined in this privacy notice unless
              otherwise required by law.
            </i>
          </p>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this privacy notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting or other legal requirements). No purpose in this
            notice will require us keeping your personal information for longer
            than the period of time in which users have an account with us.
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
        </div>
        <div id="6">
          <h2>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
          <p>
            <i>
              In Short: We aim to protect your personal information through a
              system of organizational and technical security measures.
            </i>
          </p>
          <p>
            We have implemented appropriate technical and organizational
            security measures designed to protect the security of any personal
            information we process. However, despite our safeguards and efforts
            to secure your information, no electronic transmission over the
            Internet or information storage technology can be guaranteed to be
            100% secure, so we cannot promise or guarantee that hackers,
            cybercriminals, or other unauthorized third parties will not be able
            to defeat our security, and improperly collect, access, steal, or
            modify your information. Although we will do our best to protect
            your personal information, transmission of personal information to
            and from our Website is at your own risk. You should only access the
            Website within a secure environment.
          </p>
        </div>
        <div id="7">
          <h2>7. DO WE COLLECT INFORMATION FROM MINORS?</h2>
          <p>
            <i>
              In Short: We do not knowingly collect data from or market to
              children under 18 years of age.
            </i>
          </p>
          <p>
            We do not knowingly solicit data from or market to children under 18
            years of age. By using the Website, you represent that you are at
            least 18 or that you are the parent or guardian of such a minor and
            consent to such minor dependent’s use of the Website. If we learn
            that personal information from users less than 18 years of age has
            been collected, we will deactivate the account and take reasonable
            measures to promptly delete such data from our records. If you
            become aware of any data we may have collected from children under
            age 18, please contact us at
            <b>
              <i>
                <a href="mailto:team@wicebot.guru">
                  team@wicebot.guru
                </a>
              </i>
            </b>
            .
          </p>
        </div>
        <div id="8">
          <h2>8. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
          <p>
            <i>
              In Short: You may review, change, or terminate your account at any
              time.
            </i>
          </p>
          <p>
            If you are a resident in the EEA or UK and you believe we are
            unlawfully processing your personal information, you also have the
            right to complain to your local data protection supervisory
            authority. You can find their contact details here:
            <a
              href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
        "
            >
              https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
            </a>
          </p>
          <p>
            If you are a resident in Switzerland, the contact details for the
            data protection authorities are available here:
            <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
              https://www.edoeb.admin.ch/edoeb/en/home.html
            </a>
            .
          </p>
          <p>
            If you have questions or comments about your privacy rights, you may
            email us at{" "}
            <b>
              <i>
                <a href="mailto:team@wicebot.guru">
                  team@wicebot.guru
                </a>
              </i>
            </b>
            .
          </p>
          <h4>Account Information</h4>
          <p>
            If you would at any time like to review or change the information in
            your account or terminate your account, you can:
          </p>
          <ul>
            <li>
              Log in to your account settings and update your user account.
            </li>
          </ul>
          <p>
            Upon your request to terminate your account, we will deactivate or
            delete your account and information from our active databases.
            However, we may retain some information in our files to prevent
            fraud, troubleshoot problems, assist with any investigations,
            enforce our Terms of Use and/or comply with applicable legal
            requirements.
          </p>
          <p>
            <u>
              {" "}
              <b>Cookies and similar technologies:</b>
            </u>
            Most Web browsers are set to accept cookies by default. If you
            prefer, you can usually choose to set your browser to remove cookies
            and to reject cookies. If you choose to remove cookies or reject
            cookies, this could affect certain features or services of our
            Website. To opt-out of interest-based advertising by advertisers on
            our Website visit
            <a href="http://www.aboutads.info/choices/">
              http://www.aboutads.info/choices/
            </a>
            .
          </p>
          <p>
            <u>
              <b>Opting out of email marketing:</b>
            </u>{" "}
            You can unsubscribe from our marketing email list at any time by
            clicking on the unsubscribe link in the emails that we send or by
            contacting us using the details provided below. You will then be
            removed from the marketing email list — however, we may still
            communicate with you, for example to send you service-related emails
            that are necessary for the administration and use of your account,
            to respond to service requests, or for other non-marketing purposes.
            To otherwise opt-out, you may:
          </p>
          <ul>
            <li>Access your account settings and update your preferences.</li>
          </ul>
        </div>
        <div id="9">
          <h2>9. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
          <p>
            Most web browsers and some mobile operating systems and mobile
            applications include a Do-Not-Track ("DNT") feature or setting you
            can activate to signal your privacy preference not to have data
            about your online browsing activities monitored and collected. At
            this stage no uniform technology standard for recognizing and
            implementing DNT signals has been finalized. As such, we do not
            currently respond to DNT browser signals or any other mechanism that
            automatically communicates your choice not to be tracked online. If
            a standard for online tracking is adopted that we must follow in the
            future, we will inform you about that practice in a revised version
            of this privacy notice.
          </p>
        </div>
        <div id="10">
          <h2>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
          <p>
            <i>
              In Short: Yes, if you are a resident of California, you are
              granted specific rights regarding access to your personal
              information.
            </i>
          </p>
          <p>
            California Civil Code Section 1798.83, also known as the "Shine The
            Light" law, permits our users who are California residents to
            request and obtain from us, once a year and free of charge,
            information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the
            names and addresses of all third parties with which we shared
            personal information in the immediately preceding calendar year. If
            you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact
            information provided below.
          </p>
          <p>
            If you are under 18 years of age, reside in California, and have a
            registered account with the Website, you have the right to request
            removal of unwanted data that you publicly post on the Website. To
            request removal of such data, please contact us using the contact
            information provided below, and include the email address associated
            with your account and a statement that you reside in California. We
            will make sure the data is not publicly displayed on the Website,
            but please be aware that the data may not be completely or
            comprehensively removed from all our systems (e.g. backups, etc.).
          </p>
        </div>
        <div id="11">
          <h2>11. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
          <p>
            <i>
              In Short: Yes, we will update this notice as necessary to stay
              compliant with relevant laws.
            </i>
          </p>
          <p>
            We may update this privacy notice from time to time. The updated
            version will be indicated by an updated "Revised" date and the
            updated version will be effective as soon as it is accessible. If we
            make material changes to this privacy notice, we may notify you
            either by prominently posting a notice of such changes or by
            directly sending you a notification. We encourage you to review this
            privacy notice frequently to be informed of how we are protecting
            your information.
          </p>
        </div>
        <div id="12">
          <h2>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
          <p>
            If you have questions or comments about this notice, you may email
            us at
            <b>
              <i>
                <a href="mailto:team@wicebot.guru">
                  team@wicebot.guru
                </a>
              </i>
            </b>{" "}
            .
          </p>
          <p>
            <b>wicebot</b>
            <br />
            Hir-Asha Arcade, Opp Sagar Sangeet Apt
            <br />
            Kargil - Sola Road
            <br />
            Ahmedabad, Gujarat 380060
            <br />
            India
          </p>
        </div>
        <div id="13">
          <h2>
            13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </h2>
          <p>
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it in some circumstances. To
            request to review, update, or delete your personal information,
            please submit a request form by clicking <a href="#">here</a>.
          </p>
        </div>
        <div id="14">
          <h2>14.CREDIT:</h2>
          <ul>
            <li>
              This privacy policy was created by
              <a href="https://termly.io/products/privacy-policy-generator/?_gl=1*ae6dx4*_gcl_aw*R0NMLjE2NDI5MjA1NzMuQ2p3S0NBaUE4NjZQQmhBWUVpd0FOa0luZUdTd3cwanloNnhrS2lHTTJFb1N4SnlKdFQwLVpGQXdCQkxYald1eG0zOWhMcXl5elJPV0d4b0MtSWtRQXZEX0J3RQ..">
                Termly’s Privacy Policy Generator
              </a>
            </li>
            <li>
              Image used in this website from:
              <a href="https://www.freepik.com/free-vector/multicultural-people-standing-together_9176081.htm#query=community&position=1&from_view=search">
                https://www.freepik.com/free-vector/multicultural-people-standing-together_9176081.htm#query=community&position=1&from_view=search
              </a>
            </li>
          </ul>
        </div>
      </div>
      <MobileNavInner />
      </Layout>
    </>
  );
};

export default PrivacyPolicySection;
